<template>
  <div>
    <a-card class="card" title="基本信息" :bordered="false">
      <repository-form ref="repository" :showSubmit="false" @emitEx="getDeveloperLink" />
    </a-card>
    <a-card class="card" title="小区概况" :bordered="false">
      <general-form ref="general" :showSubmit="false" />
    </a-card>
    <a-card class="card" title="建筑规划" :bordered="false">
      <project-form ref="project" :showSubmit="false" />
    </a-card>
    <a-card class="card" title="销售信息" :bordered="false">
      <market-form ref="market" :showSubmit="false" />
    </a-card>
    <a-card class="card" title="物业信息" :bordered="false">
      <realEstate-form ref="realEstate" :showSubmit="false" />
    </a-card>

    <!-- fixed footer toolbar -->
    <footer-tool-bar :is-mobile="isMobile" :collapsed="sideCollapsed">
      <span class="popover-wrapper">
        <a-popover title="表单校验信息" overlayClassName="antd-pro-pages-forms-style-errorPopover" trigger="click" :getPopupContainer="trigger => trigger.parentNode">
          <template slot="content">
            <li v-for="item in errors" :key="item.key" @click="scrollToField(item.key)" class="antd-pro-pages-forms-style-errorListItem">
              <a-icon type="cross-circle-o" class="antd-pro-pages-forms-style-errorIcon" />
              <div class="">{{ item.message }}</div>
              <div class="antd-pro-pages-forms-style-errorField">{{ item.fieldLabel }}</div>
            </li>
          </template>
          <span class="antd-pro-pages-forms-style-errorIcon" v-if="errors.length > 0">
            <a-icon type="exclamation-circle" />{{ errors.length }}
          </span>
        </a-popover>
      </span>
      <a-button type="primary" @click="validate" :loading="loading">保存</a-button>
    </footer-tool-bar>
  </div>
</template>

<script>
import RepositoryForm from './RepositoryForm'
import GeneralForm from './GeneralForm'
import ProjectForm from './ProjectForm'
import MarketForm from './MarketForm'
import RealEstateForm from './RealEstateForm'
import FooterToolBar from '@/components/FooterToolbar'
import { baseMixin } from '@/store/app-mixin'

import moment from 'moment'
import { findByIdHouse, saveHouse } from '@/api/house'

const fieldLabels = {
  houses: '楼盘',
  dateRange: '有效期',
  countSum: '总套数',
  feature: '特色卖点'
}

export default {
  name: 'AdvancedForm',
  mixins: [baseMixin],
  components: {
    FooterToolBar,
    RepositoryForm,
    GeneralForm,
    ProjectForm,
    MarketForm,
    RealEstateForm
  },
  data () {
    return {
      houseId: '',
      houseLink: '',
      developerLink: '',
      loading: false,
      memberLoading: false,
      errors: []
    }
  },
  created () {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
  },
  mounted () {
    const { $refs: { repository, general, project, market, realEstate } } = this
    // 基本信息
    findByIdHouse(this.houseId).then(res => {
      this.developerLink = `http://11.0.2.101:8911/rest/developers/${res.developer.id}`
      let key = null
      for (key in res) {
        if (res[key] === null) {
          res[key] = ''
        }
      }
      // 基本信息
      repository.form.setFieldsValue({
        name: res.name,
        code: `${res.code}`,
        type: res.type === 1 ? '商业' : '住宅',
        area: [res.province, res.city, res.district, res.street],
        developer: res.developer.name,
        address: res.address,
        addressSale: res.addressSale,
        phone: res.phone,
        cooperationTime: [moment(new Date(res.coopStartTime)).format('YYYY-MM-DD HH:mm:ss').replace(/-/g, '/'), moment(new Date(res.coopEndTime)).format('YYYY-MM-DD HH:mm:ss').replace(/-/g, '/')]
      })
      let propertyType = res.propertyType
      if (propertyType) {
        if (propertyType.indexOf(',') !== -1) {
          propertyType = propertyType.split(',')
        } else {
          propertyType = [propertyType]
        }
        repository.form.setFieldsValue({
          propertyType: propertyType
        })
      }
      // 小区概况
      general.form.setFieldsValue({
        electricity: res.electricity,
        heat: res.heat,
        water: res.water,
        decorate: res.decorate,
        green: res.green,
        plotratio: res.plotratio,
        floorArea: res.floorArea,
        acreage: res.acreage
      })
      // 建筑规划
      project.form.setFieldsValue({
        term: res.term,
        deliveryTime: res.deliveryTime,
        openTime: res.openTime
      })
      // 销售信息 market
      market.form.setFieldsValue({
        saleStatus: res.saleStatus === 1 ? '在售' : '待售',
        salePrice: res.salePrice,
        saleArea: res.saleArea
      })
      // 物业信息 realEstate
      realEstate.form.setFieldsValue({
        propertyCompany: res.propertyCompany,
        minPropertyPrice: res.minPropertyPrice,
        maxPropertyPrice: res.maxPropertyPrice,
        propertyParkSlot: res.propertyParkSlot,
        propertyParkPercent: res.propertyParkPercent,
        elevator: res.elevator
      })
    })
  },
  methods: {
    getDeveloperLink (data) {
      this.developerLink = data
      console.log(this.developerLink, 'this.developerLink');
    },
    handleSubmit (e) {
      e.preventDefault()
    },
    newMember () {
      const length = this.data.length
      this.data.push({
        key: length === 0 ? '1' : (parseInt(this.data[length - 1].key) + 1).toString(),
        name: '',
        telId: '',
        department: '',
        editable: true,
        isNew: true
      })
    },
    remove (key) {
      const newData = this.data.filter(item => item.key !== key)
      this.data = newData
    },
    saveRow (record) {
      this.memberLoading = true
      const { key, name, telId, department } = record
      if (!name || !telId || !department) {
        this.memberLoading = false
        this.$message.error('请填写完整信息。')
        return
      }
      // 模拟网络请求、卡顿 800ms
      new Promise((resolve) => {
        setTimeout(() => {
          resolve({ loop: false })
        }, 800)
      }).then(() => {
        const target = this.data.find(item => item.key === key)
        target.editable = false
        target.isNew = false
        this.memberLoading = false
      })
    },
    toggle (key) {
      const target = this.data.find(item => item.key === key)
      target._originalData = { ...target }
      target.editable = !target.editable
    },
    getRowByKey (key, newData) {
      const data = this.data
      return (newData || data).find(item => item.key === key)
    },
    cancel (key) {
      const target = this.data.find(item => item.key === key)
      Object.keys(target).forEach(key => { target[key] = target._originalData[key] })
      target._originalData = undefined
    },

    // 最终全页面提交
    validate () {
      const { $refs: { repository, general, project, market, realEstate } } = this
      const repositoryForm = new Promise((resolve, reject) => {
        repository.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })
      const generalForm = new Promise((resolve, reject) => {
        general.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })
      const projectForm = new Promise((resolve, reject) => {
        project.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })
      const marketForm = new Promise((resolve, reject) => {
        market.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })
      const realEstateForm = new Promise((resolve, reject) => {
        realEstate.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })

      // clean this.errors
      this.errors = []
      Promise.all([repositoryForm, generalForm, projectForm, marketForm, realEstateForm]).then(values => {
        // this.$notification['success']({
        //   message: '编辑楼盘',
        //   description: '编辑成功'
        // })
        console.log(values, 'values')
        const requestParameters = {
          // 基本信息
          id: this.houseId,
          name: values[0].name,
          code: values[0].code,
          type: values[0].type === '住宅' ? '0' : '1',
          addressSale: values[0].addressSale,
          developer: this.developerLink,
          propertyType: values[0].propertyType.join(',') !== undefined ? values[0].propertyType.join(',') : '',
          province: values[0].area[0],
          city: values[0].area[1],
          district: values[0].area[2],
          street: values[0].area[3],
          address: values[0].address,
          phone: values[0].phone,
          coopStartTime: moment(new Date(values[0].cooperationTime[0])).format('YYYY-MM-DD HH:mm:ss'),
          coopEndTime: moment(new Date(values[0].cooperationTime[1])).format('YYYY-MM-DD HH:mm:ss'),
          // 小区概况
          electricity: values[1].electricity,
          heat: values[1].heat,
          water: values[1].water,
          decorate: values[1].decorate,
          green: values[1].green,
          plotratio: values[1].plotratio,
          floorArea: values[1].floorArea,
          acreage: values[1].acreage,
          // 建筑规划
          term: values[2].term,
          deliveryTime: values[2].deliveryTime === '' ? '' : moment(new Date(values[2].deliveryTime)).format('YYYY-MM-DD HH:mm:ss'),
          openTime: values[2].openTime === '' ? '' : moment(new Date(values[2].openTime)).format('YYYY-MM-DD HH:mm:ss'),
          // 销售信息
          saleStatus: values[3].saleStatus === '在售' ? '1' : '0',
          salePrice: values[3].salePrice,
          saleArea: values[3].saleArea,
          // 物业信息
          propertyCompany: values[4].propertyCompany,
          minPropertyPrice: values[4].minPropertyPrice,
          maxPropertyPrice: values[4].maxPropertyPrice,
          propertyParkSlot: values[4].propertyParkSlot,
          propertyParkPercent: `${values[4].propertyParkPercent}`,
          elevator: `${values[4].elevator}`
        }
        console.log(requestParameters, 'requestParameters')
        saveHouse(requestParameters).then(res => {
          this.$message.success(`成功保存楼盘信息`, 2)
        })
      }).catch(() => {
        const errors = Object.assign({}, repository.form.getFieldsError(), general.form.getFieldsError(), project.form.getFieldsError(), market.form.getFieldsError(), realEstate.form.getFieldsError())
        const tmp = { ...errors }
        this.errorList(tmp)
      })
    },
    errorList (errors) {
      if (!errors || errors.length === 0) {
        return
      }
      this.errors = Object.keys(errors)
        .filter(key => errors[key])
        .map(key => ({
          key: key,
          message: errors[key][0],
          fieldLabel: fieldLabels[key]
        }))
    },
    scrollToField (fieldKey) {
      const labelNode = document.querySelector(`label[for="${fieldKey}"]`)
      if (labelNode) {
        labelNode.scrollIntoView(true)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .card{
    margin-bottom: 24px;
  }
  .popover-wrapper {
    /deep/ .antd-pro-pages-forms-style-errorPopover .ant-popover-inner-content {
      min-width: 256px;
      max-height: 290px;
      padding: 0;
      overflow: auto;
    }
  }
  .antd-pro-pages-forms-style-errorIcon {
    user-select: none;
    margin-right: 24px;
    color: #f5222d;
    cursor: pointer;
    i {
          margin-right: 4px;
    }
  }
  .antd-pro-pages-forms-style-errorListItem {
    padding: 8px 16px;
    list-style: none;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      background: #e6f7ff;
    }
    .antd-pro-pages-forms-style-errorIcon {
      float: left;
      margin-top: 4px;
      margin-right: 12px;
      padding-bottom: 22px;
      color: #f5222d;
    }
    .antd-pro-pages-forms-style-errorField {
      margin-top: 2px;
      color: rgba(0,0,0,.45);
      font-size: 12px;
    }
  }
</style>
