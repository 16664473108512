<template>
  <a-form @submit="handleSubmit" :form="form" class="form">
    <a-row class="form-row" :gutter="16">
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="供电方式">
          <a-input
            placeholder="请输入供电方式"
            v-decorator="[
              'electricity'
            ]" />
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="供暖方式">
          <a-input
            placeholder="请输入供暖方式"
            v-decorator="[
              'heat'
            ]" />
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="供水方式">
          <a-input
            placeholder="请输入供水方式"
            v-decorator="[
              'water'
            ]" />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="form-row" :gutter="16">
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="容积率（%）">
          <a-input-number 
            :min="0"
            placeholder="请输入容积率"
            v-decorator="[
              'plotratio'
            ]" />
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="装修标准">
          <a-input
            placeholder="请输入装修标准"
            v-decorator="[
              'decorate'
            ]" />
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="绿化率（%）">
          <a-input-number 
            :min="0"
            placeholder="请输入绿化率（%）"
            v-decorator="[
              'green'
            ]" />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="form-row" :gutter="16">
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="占地面积（㎡）">
          <a-input-number 
            :min="0"
            placeholder="请输入占地面积"
            v-decorator="[
              'floorArea'
            ]" />
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="建筑面积（㎡）">
          <a-input-number 
            :min="0"
            placeholder="请输入建筑面积"
            v-decorator="[
              'acreage'
            ]" />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item v-if="showSubmit">
      <a-button htmlType="submit" >Submit</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
export default {
  name: 'RepositoryForm',
  props: {
    showSubmit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: this.$form.createForm(this),
      gridSet: {
        label: { lg: { span: 8 }, sm: { span: 8 } },
        wraper: { lg: { span: 10 }, sm: { span: 10 } }
      },
      colSet: {
        lg: 8,
        md: 12,
        sm: 24
      }
    }
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values, '基本信息')
          // this.$notification['error']({
          //   message: 'Received values of form:',
          //   description: values
          // })
        }
      })
    },
    validate (rule, value, callback) {
      const regex = /^user-(.*)$/
      if (!regex.test(value)) {
        callback(new Error('需要以 user- 开头'))
      }
      callback()
    }
  }
}
</script>

<style scoped>

</style>
