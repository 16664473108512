import request from '@/utils/request'

const developerApi = {
  // get developer info
  developers: '/api/v1/rest/developers',
  update: '/api/v1/developer/update',
  findByKeyword: '/api/v1/rest/developers/search/findByKeyword',
  generateQcode: '/api/v1/developer/generateQcode'
}

export function developers (param) {
  return request({
    url: developerApi.developers + `?page=${param.page}&size=${param.size}&sort=${param.sort}`,
    method: 'get'
  })
}

export function addDevelopers (param) {
  return request({
    url: developerApi.developers,
    method: 'post',
    data: param
  })
}

export function updateDevelopers (param) {
  return request({
    url: developerApi.update,
    method: 'post',
    data: param
  })
}

export function getDevelopers (id) {
  return request({
    url: developerApi.developers + '/' + id,
    method: 'get'
  })
}

export function editDevelopers (id, data) {
  return request({
    url: developerApi.developers + `/${id}`,
    method: 'patch',
    data
  })
}

export function delDevelopers (param) {
  return request({
    url: developerApi.developers + `/${param.id}`,
    method: 'delete'
  })
}

export function developerGenerateQcode (param) {
  const url = request.requestFormat(developerApi.generateQcode, param)
  return request({
    url,
    method: 'get'
  })
}

export function getDevelopersContract (id) {
  return request({
    url: developerApi.developers + '/' + id + '/contract',
    method: 'get'
  })
}

export function putDevelopersContract (id, data) {
  return request({
    url: developerApi.developers + '/' + id + '/contract',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

export function findDevelopers (param) {
  let url = developerApi.findByKeyword
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function findByIdDevelopers (param) {
  return request({
    url: developerApi.developer + `/${param.id}`,
    method: 'get'
  })
}
