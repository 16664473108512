<template>
  <a-form @submit="handleSubmit" :form="form" class="form">
    <a-row class="form-row" :gutter="16">
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="物业公司">
          <a-input placeholder="请输入物业公司名称" v-decorator="['propertyCompany']" />
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="物业费（元）">
          <a-form-item :style="{ display: 'inline-block', width: 'calc(50% -12px)', height: '20px' }">
            <a-input placeholder="请输入物业费" v-decorator="['minPropertyPrice']" />
          </a-form-item>
          <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">
            -
          </span>
          <a-form-item :style="{ display: 'inline-block', width: 'calc(50% -12px)', height: '20px' }">
            <a-input placeholder="请输入物业费" v-decorator="['maxPropertyPrice']" />
          </a-form-item>
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="车位数">
          <a-input placeholder="例：地上500个、地下700个" v-decorator="['propertyParkSlot']" />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="form-row" :gutter="16">
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="车位配比">
          <a-input
            addon-before="1 ："
            placeholder="请输入车位配比"
            @input="verify"
            v-decorator="[
              'propertyParkPercent',
              {
                rules: [
                  {
                    validator: validateToStall
                  }
                ]
              }
            ]"
          />
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="梯户比">
          <a-input placeholder="例：两梯两户" @input="verify" v-decorator="['elevator']" />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item v-if="showSubmit">
      <a-button htmlType="submit">Submit</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
export default {
  name: 'RepositoryForm',
  props: {
    showSubmit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      gridSet: {
        label: { lg: { span: 8 }, sm: { span: 8 } },
        wraper: { lg: { span: 10 }, sm: { span: 10 } }
      },
      colSet: {
        lg: 8,
        md: 12,
        sm: 24
      }
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values, '基本信息')
          // this.$notification['error']({
          //   message: 'Received values of form:',
          //   description: values
          // })
        }
      })
    },
    validateToStall(rule, value, callback) {
      console.log(value, 'value');
      const myreg = /^(-?\d+)(\.\d+)?$/
      console.log(myreg.test(value), 'myreg.test(value)')
      if (value && !myreg.test(value)) {
        callback(new Error('请输入正确的数字'))
      } else {
        callback()
      }
    },
    validate(rule, value, callback) {
      const regex = /^user-(.*)$/
      if (!regex.test(value)) {
        callback(new Error('需要以 user- 开头'))
      }
      callback()
    },
    verify(val) {
      const myreg = /^[0-9]*$/
      if (!myreg.test(val)) {
        console.log(val, 'cal')
      }
    }
  }
}
</script>

<style scoped></style>
