<template>
  <a-form @submit="handleSubmit" :form="form" class="form">
    <a-row class="form-row" :gutter="16">
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="楼盘均价（元/㎡）">
          <a-input-number 
            :min="0"
            placeholder="请输入楼盘均价"
            v-decorator="[
              'salePrice'
            ]" />
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item
          label="销售状态">
          <a-select
            placeholder="请选择销售状态"
            v-decorator="[
              'saleStatus'
            ]" >
            <a-select-option value="待售">待售</a-select-option>
            <a-select-option value="在售">在售</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="可售面积">
          <a-input-number 
            :min="0"
            placeholder="请输入可售面积"
            v-decorator="[
              'saleArea'
            ]" />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item v-if="showSubmit">
      <a-button htmlType="submit" >Submit</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
export default {
  name: 'RepositoryForm',
  props: {
    showSubmit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: this.$form.createForm(this),
      gridSet: {
        label: { lg: { span: 8 }, sm: { span: 8 } },
        wraper: { lg: { span: 10 }, sm: { span: 10 } }
      },
      colSet: {
        lg: 8,
        md: 12,
        sm: 24
      }
    }
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values, '基本信息')
          // this.$notification['error']({
          //   message: 'Received values of form:',
          //   description: values
          // })
        }
      })
    },
    validate (rule, value, callback) {
      const regex = /^user-(.*)$/
      if (!regex.test(value)) {
        callback(new Error('需要以 user- 开头'))
      }
      callback()
    }
  }
}
</script>

<style scoped>

</style>
