<template>
  <a-form @submit="handleSubmit" :form="form" class="form">
    <a-row class="form-row" :gutter="16">
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="楼盘名称">
          <a-input
            placeholder="请输入楼盘名称"
            v-decorator="['name', { rules: [{ required: true, message: '请输入楼盘名称', whitespace: true }] }]"
          />
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="楼盘编码">
          <a-input
            placeholder="请输入楼盘编码"
            v-decorator="['code', { rules: [{ required: true, message: '请输入楼盘编码', whitespace: true }] }]"
          />
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="项目类型">
          <a-select placeholder="请选择项目类型" v-decorator="['type']">
            <a-select-option value="住宅">住宅</a-select-option>
            <a-select-option value="商办">商业</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="form-row" :gutter="16">
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="物业类型">
          <a-select mode="multiple" placeholder="请选择物业类型" v-decorator="['propertyType', { rules: [{ required: true, message: '请选择物业类型' }]}]">
            <a-select-option value="低密">低密</a-select-option>
            <a-select-option value="高层">高层</a-select-option>
            <a-select-option value="合院">合院</a-select-option>
            <a-select-option value="洋房">洋房</a-select-option>
            <a-select-option value="商办">商办</a-select-option>
            <a-select-option value="公寓">公寓</a-select-option>
            <a-select-option value="别墅">别墅</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="楼盘地区">
          <a-cascader v-decorator="['area']" :fieldNames="cityField" :options="options" @change="onChange" />
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="楼盘地址">
          <a-input
            placeholder="请输入楼盘地址"
            v-decorator="['address', { rules: [{ message: '请输入楼盘地址', whitespace: true }] }]"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="form-row" :gutter="16">
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="开发商">
          <a-select
            show-search
            :value="value"
            placeholder="请选择开发商"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleSearch"
            @change="handleChange"
            @focus="findAll"
            v-decorator="['developer', { rules: [{ required: true, message: '请选择开发商' }] }]"
          >
            <a-select-option v-for="d in data" :key="d.link">
              {{ d.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="售楼地址">
          <a-input
            placeholder="请输入售楼地址"
            v-decorator="['addressSale', { rules: [{ message: '请输入售楼地址', whitespace: true }] }]"
          />
        </a-form-item>
      </a-col>
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="联系电话">
          <a-input
            placeholder="请输入联系电话"
            v-decorator="['phone', { rules: [{ message: '请输入联系电话', whitespace: true }] }]"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="form-row" :gutter="16">
      <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
        <a-form-item label="合作时间">
          <a-range-picker style="width: 100%" @change="ccc" v-decorator="['cooperationTime']" />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item v-if="showSubmit">
      <a-button htmlType="submit">Submit</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { findDevelopers } from '@/api/developers'
import { defaultCityOptions } from '@/common/city'

let timeout
let currentValue

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
  currentValue = value

  function fake() {
    const params = {
      keyword: currentValue,
      page: 0,
      size: 1000,
      sort: ''
    }
    findDevelopers(params).then(d => {
      console.log(d, 'd')
      if (currentValue === value) {
        const result = d._embedded.developers
        const data = []
        result.forEach(r => {
          data.push({
            id: r.id,
            value: r.name,
            text: r.name,
            link: r._links.self.href
          })
        })
        callback(data)
      }
    })
  }

  timeout = setTimeout(fake, 300)
}
export default {
  name: 'RepositoryForm',
  props: {
    showSubmit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      cityField: { label: 'name', value: 'name', children: 'children' },
      // search
      data: [],
      value: undefined,
      gridSet: {
        label: { lg: { span: 8 }, sm: { span: 8 } },
        wraper: { lg: { span: 10 }, sm: { span: 10 } }
      },
      colSet: {
        lg: 8,
        md: 12,
        sm: 24
      }
    }
  },
  computed: {
    options() {
      const city = defaultCityOptions
      if (this.$store.state.city.city) {
        return this.$store.state.city.city
      } else {
        return city
      }
    }
  },
  methods: {
    ccc(value) {
      console.log(value)
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values, '基本信息')
          // this.$notification['error']({
          //   message: 'Received values of form:',
          //   description: values
          // })
        }
      })
    },
    validate(rule, value, callback) {
      const regex = /^user-(.*)$/
      if (!regex.test(value)) {
        callback(new Error('需要以 user- 开头'))
      }
      callback()
    },
    onChange(value) {
      console.log(value, 'city')
    },
    // search
    handleSearch(value) {
      fetch(value, data => (this.data = data))
    },
    handleChange(value, node) {
      this.$emit('emitEx', value)
      this.value = value
      fetch(value, data => (this.data = data))
    },
    findAll(value) {
      fetch('', data => (this.data = data))
    }
  }
}
</script>

<style scoped></style>
